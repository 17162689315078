<template>
  <div>
    <!--    <div class="TheNavigation">
      <router-link :to="$i18nRoute({ name: 'HomePage'})">Home</router-link>
      <router-link :to="$i18nRoute({ name: 'existing_member'})">Existing Member</router-link>
      <router-link :to="$i18nRoute({ name: 'reg_form'})">Register</router-link>
    </div>
    -->

    <!-- 20200602 -->
    <div>
      <b-navbar toggleable="lg" type="light" variant="light" fixed="top">
        <b-navbar-brand
          to="/"
          style="min-width: 150px; text-align: left; color: orange;"
          >WINT</b-navbar-brand
        >

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav>
            <b-nav-item-dropdown :text="$t('main.accounting')" right>
              <!-- General Ledger -->
              <b-dropdown-item :to="$i18nRoute({ name: 'GeneralLedger' })">{{
                $t('main.generalLedger')
              }}</b-dropdown-item>
              <!-- Accounts Receivable -->
              <b-dropdown-item
                :to="$i18nRoute({ name: 'AccountsReceivable' })"
                >{{ $t('main.accountsReceivable') }}</b-dropdown-item
              >
              <!-- Accounts Payable -->
              <b-dropdown-item :to="$i18nRoute({ name: 'AccountsPayable' })">{{
                $t('main.accountsPayable')
              }}</b-dropdown-item>
              <b-dropdown-item :to="$i18nRoute({ name: 'CashAndBank' })">
                {{ $t('main.cashAndBank') }}
              </b-dropdown-item>
              <b-dropdown-item :to="$i18nRoute({ name: 'LedgerListing' })">
                {{ $t('main.ledgerListing') }}
              </b-dropdown-item>
              <b-dropdown-item :to="$i18nRoute({ name: 'ChartOfAccounts' })">{{
                $t('main.chartOfAccounts')
              }}</b-dropdown-item>
              <!-- Customer Base -->
              <b-dropdown-item :to="$i18nRoute({ name: 'CustomerBase' })">
                {{ $t('main.customerBase') }}
              </b-dropdown-item>
              <!-- Reconcile Accounts Receivable -->
              <b-dropdown-item
                :to="$i18nRoute({ name: 'ReconcileAccountsReceivable' })"
                >{{ $t('main.reconcileAccountsReceivable') }}
              </b-dropdown-item>
            </b-nav-item-dropdown>

            <!-- Financial Report -->
            <b-nav-item-dropdown :text="$t('main.financialReport')" right>
              <!-- Balance Sheet -->
              <b-dropdown-item :to="$i18nRoute({ name: 'BalanceSheet' })">{{
                $t('main.balanceSheet')
              }}</b-dropdown-item>
              <!-- Income Statement -->
              <b-dropdown-item :to="$i18nRoute({ name: 'IncomeStatement' })">{{
                $t('main.incomeStatement')
              }}</b-dropdown-item>
              <!-- Trial Balance -->
              <b-dropdown-item :to="$i18nRoute({ name: 'TrialBalance' })">{{
                $t('main.trialBalance')
              }}</b-dropdown-item>
            </b-nav-item-dropdown>
            <!-- End of Financial Report -->

            <!-- Human Resources -->
            <b-nav-item-dropdown :text="$t('main.humanResources')" right>
              <!-- Balance Sheet -->
              <b-dropdown-item :to="$i18nRoute({ name: 'Employee' })">{{
                $t('main.employee')
              }}</b-dropdown-item>
              <b-dropdown-item :to="$i18nRoute({ name: 'PayrollDetails' })">{{
                $t('main.payrollDetails')
              }}</b-dropdown-item>
            </b-nav-item-dropdown>
          </b-navbar-nav>

          <!-- Right aligned nav items -->
          <b-navbar-nav class="ml-auto">
            <!-- Lang -->
            <b-nav-item>
              <template v-for="(val, lang) in supportedLanguagesMapping">
                <button
                  :key="lang"
                  v-if="!isCurrentLanguage(lang)"
                  v-on:click="btnChangeLang"
                  :value="lang"
                  class="LangBtn"
                  style="border: none; background: none; color: rgba(0, 0, 0, 0.5);"
                >
                  {{ val }}
                </button>
              </template>
            </b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
    </div>
    <!-- 20200602 -->
  </div>
</template>

<script>
import { Trans } from '@/plugins/Translation'

export default {
  name: 'app',
  data: function() {
    return {
      records: {
        year: new Date().getFullYear()
      }
    }
  },
  computed: {
    supportedLanguages() {
      return Trans.supportedLanguages
    },
    currentLanguage() {
      return Trans.currentLanguage
    },
    supportedLanguagesMapping() {
      return Trans.supportedLanguagesMapping
    }
  },
  methods: {
    changeLanguage(e) {
      const lang = e.target.value
      const to = this.$router.resolve({ params: { lang } })

      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    },
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage
    },
    btnChangeLang(e) {
      const lang = e.target.value
      const to = this.$router.resolve({ params: { lang } })
      return Trans.changeLanguage(lang).then(() => {
        this.$router.push(to.location)
      })
    }
  }
}
</script>

<style>
.TheNavigation {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}
.TheNavigation a {
  text-decoration: none;
  font-weight: bold;
  color: #535353;
  margin: 0 0.5rem;
}
.TheNavigation a:hover {
  color: #333;
}
</style>
